<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useStore } from '@/stores/main.store';

import { Modal } from '@/helpers/modal.helper';

import DataTransferService from '@/services/data-transfer.service';
import NotificationService from '@/services/notifications.service';

import DataRequestReceivedModal from '@/components/modals/DataRequestReceivedModal.vue';
import { waitFor } from '@/helpers/wait-for.helper';

Modal.register([DataRequestReceivedModal]);

// Composables

const route = useRoute();
const store = useStore();

// Data

const notificationsPollingTimer = ref(-1);
const dataPollingTimer = ref(-1);
const hasFetchedSubscriptions = ref(false);

// Watchers

watch(
    () => $auth.user,
    async (user) => {
        if (!hasFetchedSubscriptions.value) {
            await waitFor.change(computed(() => $subscription.currentAccount));

            hasFetchedSubscriptions.value = true;
        }

        if (user === null) {
            clearInterval(notificationsPollingTimer.value);
            clearInterval(dataPollingTimer.value);

            notificationsPollingTimer.value = -1;
            dataPollingTimer.value = -1;

            return;
        }

        if (dataPollingTimer.value === -1) {
            notificationsPollingTimer.value = setInterval(handleNotificationsPolling, 60000);
            dataPollingTimer.value = setInterval(handleDataPolling, 60000);

            handleDataPolling();
            handleNotificationsPolling();
        }
    },
);

// Functions

/**
 * Polling for data transfers
 */
async function handleDataPolling() {
    if (!$subscription.currentAccount) {
        return;
    }

    const [response, error] = await DataTransferService.get();

    if (error) {
        return;
    }

    store.dataTransfers = response.data;

    if (store.dataTransfers.length > 0) {
        const dataTransfer = store.dataTransfers[0];

        Modal.open('DataRequestReceivedModal', {
            recipientNumber: dataTransfer.requester.phoneNumber,
            id: dataTransfer.id,
            amount: dataTransfer.dataAmount,
        });
    }
}

/**
 * Polling for notifications
 */
async function handleNotificationsPolling() {
    if (route.name === 'notifications') {
        return;
    }

    const [response, error] = await NotificationService.get();

    if (error) {
        return;
    }

    store.unreadNotifications = response.data.totalUnread;
    store.notifications = response.data.items;
}
</script>

<template></template>
