export default async function subscriptionGuard(to, from, next) {
    await $auth.waitUntilUserIsFetched();

    if (to.name === 'home' && !$subscription.currentAccount && !$subscription.orders.length) {
        return next({ name: 'home-welcome' });
    }

    if (to.name === 'home-welcome' && $subscription.currentAccount?.planId) {
        return next({ name: 'home' });
    }

    return next();
}
