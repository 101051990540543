<script setup lang="ts">
import { toRaw } from 'vue';

import { Modal } from '@/helpers/modal.helper';

window.addEventListener('keydown', (e) => {
    if (e.key == 'Escape') {
        // Close the "active" modal.
        Modal.close();
    }
});
</script>

<template>
    <component
        v-for="modal in Modal.registeredModals.value"
        :key="modal?.name"
        :is="toRaw(modal?.component)"
        :active="modal?.active"
        :name="modal?.name"
        v-bind="modal?.data"
        @close="Modal.close(modal.name)"
    ></component>
</template>
