import { computed, ref, type ComputedRef, type Ref } from 'vue';

import { defineStore } from 'pinia';

import type Order from '@/models/order.model';
import type { Plan } from '@/models/plan.model';
import type { PlanResponse } from '@/models/plan.model';
import type Subscription from '@/models/subscription.model';
import type { Member, SubscriptionResponse } from '@/models/subscription.model';

export const useSubscriptionStore = defineStore('subscription', () => {
    const currentAccount = ref<Subscription>();
    const otherAccounts: Ref<Subscription[]> = ref([]);
    const isOwner: Ref<boolean> = ref(true);
    const backupSubscription = ref<(Order & { plan?: Plan })>();
    const allPlans: Ref<Plan[]> = ref([]);
    const orders: Ref<Order[]> = ref([]);

    // Computed

    const plan = computed(() => {
        const planId = currentAccount.value?.planId || backupSubscription.value?.planId;

        if (!planId) {
            return;
        }

        return allPlans.value.find((plan) => plan.planId === planId);
    });

    const hasIncompleteOrders = computed(() => orders.value.some((order) => !order.isComplete));

    /*
     * Family
     */
    const isCurrentAccountAFamilyPlan = computed(() => $store.planResponse?.find((plan) => plan.id === currentAccount.value?.planTypeId)?.name === 'family_plan' || false);

    const familyAccount = computed(() => {
        if (isCurrentAccountAFamilyPlan.value) {
            return currentAccount.value;
        }

        return otherAccounts.value.find((account) => findPlanByTypeId(account?.planTypeId)?.name === 'family_plan') || null;
    });

    const familyMembers = computed(() => {
        const familyPlanAccounts = [...otherAccounts.value].filter((account) => findPlanByTypeId(account?.planTypeId)?.name === 'family_plan');

        let familyMembers: Member[] = [];

        if (familyPlanAccounts.length) {
            for (const account of familyPlanAccounts) {
                if (!account.members) {
                    continue;
                }

                familyMembers = [...familyMembers, ...account.members];
            }
        }

        const otherFamilyUnderCurrentAccount: Member[] = currentAccount.value?.otherMembers || [];

        return [...familyMembers, ...otherFamilyUnderCurrentAccount];
    });

    const familyMembersProcessing = computed(() => orders.value.filter((order) => !order.isComplete && findPlan(order.planId)?.type === 'family_plan'));

    const otherFamilyMembersProcessing = computed(() => orders.value.filter((order) => !order.isComplete && findPlan(order.planId)?.type === 'family_plan'));

    const allFamilyMembers = computed(() => [...familyMembers.value, ...familyMembersProcessing.value]);

    const canAddMoreFamilyMembers = computed(() => {
        if (plan.value?.type !== 'family_plan' || !$subscription.currentAccount) {
            return false;
        }

        const maxMembers = plan.value.maxMembers;

        return maxMembers > allFamilyMembers.value.length;
    });

    const nextFamilyMemberPrice = computed(() => {
        if (plan.value?.type !== 'family_plan') {
            return 0;
        }

        const index =
            allFamilyMembers.value.length > plan.value.memberPrices.length - 1
                ? plan.value.memberPrices.length - 1
                : allFamilyMembers.value.length;

        return plan.value.memberPrices[index]?.price || 0;
    });

    /*
     * Individuals
     */
    const isCurrentAccountAnIndividualPlan = computed(() => $store.planResponse.find((plan) => plan.id === currentAccount.value?.planTypeId)?.name === 'individual_plan');

    const individuals = computed(() => [...otherAccounts.value].filter((account) => findPlanByTypeId(account?.planTypeId)?.name === 'individual_plan'));

    const individualsProcessing = computed(() => orders.value.filter((order) => !order.isComplete && findPlan(order.planId)?.type === 'individual_plan'));

    const allAccounts: ComputedRef<Subscription[]> = computed(() => {
        const accounts = [...otherAccounts.value];

        if (currentAccount.value) {
            accounts.unshift(currentAccount.value);
        }

        return accounts;
    });

    // Functions

    function findPlan(planId: string | undefined) {
        if (!planId) {
            return;
        }

        return allPlans.value.find((plan) => plan.planId == planId);
    }

    function findPlanByTypeId(planTypeId: string | undefined) {
        if (!planTypeId) {
            return;
        }

        return $store.planResponse?.find((plan) => plan.id === planTypeId);
    }

    function getAccount(phoneNumber: string) {
        if (!allAccounts.value) {
            return;
        }

        let returnAccount: any = null;

        for (const account of allAccounts.value) {
            if (account?.currentMember?.phoneNumber === phoneNumber) {
                returnAccount = account;
            }

            if (account?.members?.find((member) => member.phoneNumber === phoneNumber)) {
                returnAccount = account;
            }

            if (account?.otherMembers?.find((member) => member.phoneNumber === phoneNumber)) {
                returnAccount = account;
            }
        }

        return returnAccount;
    };

    function loadPlans(planResponse: PlanResponse) {
        allPlans.value = [];

        for (const data of planResponse) {
            allPlans.value = [...allPlans.value, ...data.dataPlanResponses.map((plan) => ({ ...plan, planTypeName: data.name }))];
        }
    };

    function setSubscriptionData(data: SubscriptionResponse) {
        currentAccount.value = data.currentAccount;
        otherAccounts.value = data.otherAccounts;
        isOwner.value = data.isOwner;
    };

    async function resetSubscriptionData() {
        currentAccount.value = undefined;
        otherAccounts.value = [];
        backupSubscription.value = undefined;
        isOwner.value = true;
    };

    return {
        isOwner,
        currentAccount,
        otherAccounts,
        backup: backupSubscription,
        allPlans,
        orders,

        // Computed

        plan,
        hasIncompleteOrders,

        isCurrentAccountAFamilyPlan,
        familyAccount,
        familyMembers,
        familyMembersProcessing,
        otherFamilyMembersProcessing,
        allFamilyMembers,
        canAddMoreFamilyMembers,
        nextFamilyMemberPrice,

        isCurrentAccountAnIndividualPlan,
        individuals,
        individualsProcessing,
        allAccounts,

        // Functions

        findPlanByTypeId,

        setSubscriptionData,

        getAccount,

        loadPlans,

        resetSubscriptionData,
    };
});
