import ActionService from '@/services/action.service';
import AgreementService from '@/services/agreement.service';
import OrderService from '@/services/order.service';
import PackageService from '@/services/package.service';
import PlansService from '@/services/plans.service';
import SubscriptionService from '@/services/subscription.service';

async function getActions() {
    const [response, error] = await ActionService.get();

    if (error) {
        return;
    }

    $store.actions = response.data;
}

async function getSubscription() {
    const [response, error] = await SubscriptionService.get();

    if (error) {
        return;
    }

    $subscription.currentAccount = response.data.currentAccount;
    $subscription.otherAccounts = response.data.otherAccounts;
    $subscription.isOwner = response.data.isOwner;
}

async function getPlans() {
    const [response, error] = await PlansService.get();

    if (error) {
        return;
    }

    $store.planResponse = response.data;

    $subscription.loadPlans(response.data);
}

async function getOrders() {
    const [response, error] = await OrderService.get();

    if (error) {
        return;
    }

    $subscription.orders = response.data.filter((order) => !order.isCancelled);

    // If we have a "main subscription" order, we need to populate the backup subscription.

    const order = $subscription.orders.find((order) => order.isMainSubscription && !order.isComplete);

    if (order) {
        $subscription.backup = order;
    }
}

async function getPackages() {
    const [response, error] = await PackageService.get();

    if (error) {
        return;
    }

    $store.packages = response.data;
}

async function getAgreement() {
    const [response, error] = await AgreementService.get();

    if (error) {
        return;
    }

    $store.agreement = response.data;
}

export async function prefetch() {
    const user = await $auth.getUser();

    if (!user) {
        $auth.userIsFetched = true;

        return;
    }

    await Promise.all([getActions(), getPackages(), getSubscription(), getPlans(), getOrders(), getAgreement()]);

    $auth.userIsFetched = true;
}

export async function refreshSubscription() {
    await getSubscription();
    await getOrders();
}
