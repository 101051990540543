export default [
    {
        path: '/nytt-hald/:planType',
        name: 'new-subscription',
        component: () => import('@/views/CreateSubscriptionView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Stovna hald'),
            },
        },
    },
    {
        path: '/legg-hald-afturat',
        name: 'add-subscription',
        component: () => import('@/views/AddSubscriptionView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Keyp einstaklingahald'),
            },
        },
    },
    {
        path: '/stovna-familju-lim',
        name: 'add-family-member',
        component: () => import('@/views/AddFamilyMemberView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Legg familjulim afturat'),
            },
        },
    },
    {
        // A family member transfers themself out of a family plan.
        path: '/flyt-til-einstaklingahald',
        name: 'transfer-to-own-individual',
        component: () => import('@/views/transfer/TransferToOwnIndividualView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        // A family member transfers themself out of a family plan.
        path: '/flyt-til-familjuhald',
        name: 'transfer-to-own-family',
        component: () => import('@/views/transfer/TransferToOwnFamilyView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        path: '/broyt-til-einstaklingahald',
        name: 'transfer-family-to-individual',
        component: () => import('@/views/transfer/TransferFamilyToIndividualView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        // A family member is transferred out of a family plan by the owner.
        path: '/broyt-til-familjuhald',
        name: 'transfer-individual-to-family',
        component: () => import('@/views/transfer/TransferIndividualToFamilyView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        // A family member is transferred out of a family plan by the owner.
        path: '/flyt-hald/familju-lim/:id',
        name: 'transfer-family-member',
        component: () => import('@/views/transfer/TransferFamilyMemberToIndividualView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        path: '/dagfor-hald/:id',
        name: 'subscription-update',
        component: () => import('@/views/UpdateSubscriptionView.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => {
            return {
                name: 'home',
            };
        },
    },
];
