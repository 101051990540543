import { computed } from 'vue';

export default [
    {
        path: '/menu',
        component: () => import('@/views/menu/MenuView.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '',
                name: 'menu',
                component: () => import('@/views/menu/MenuMainView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: false,
                        title: computed(() => $t('Menu')),
                    },
                },
            },
            {
                path: 'kvittanir',
                name: 'menu.receipts',
                component: () => import('@/views/menu/ReceiptsView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Kvittanir')),
                    },
                },
            },
            {
                path: 'treytir',
                name: 'menu.terms',
                component: () => import('@/views/MarkdownView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Treytir')),
                    },
                    markdown: 'terms',
                },
            },
            {
                path: 'privatlivspolitikkur',
                name: 'menu.privacy',
                component: () => import('@/views/MarkdownView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Privatlívspolitikkur')),
                    },
                    markdown: 'privacy',
                },
            },
            {
                path: 'mal',
                name: 'menu.language',
                component: () => import('@/views/menu/LanguageView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Mál')),
                    },
                },
            },
        ],
    },
];
