<script setup lang="ts">
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { Modal } from '@/helpers/modal.helper';

import VFooterItem from '@/components/VFooterItem.vue';
import ChangeAppModal from '@/components/modals/ChangeAppModal.vue';

Modal.register([ChangeAppModal]);

// Composables

const route = useRoute();

// Computed

const showFooter = computed(() => route.meta.footer);

// Functions

function isActive(name: string): boolean {
    const routeName = route.name?.toString();

    if (!routeName) {
        return false;
    }

    return routeName?.includes(name);
}

async function open2fyri1() {
    if (Capacitor.getPlatform() !== 'web') {
        // MíttNema is used as an app
        const { value } = await AppLauncher.canOpenUrl({ url: 'fo.nema.nema' });

        // 2fyri1 is not installed, open dialog
        if (value) {
            // 2fyri1 is installed, open app
            await AppLauncher.openUrl({ url: 'fo.nema.nema' });
            return;
        }
    }
    // MíttNema is used in the browser
    Modal.open('ChangeAppModal');
}
</script>

<template>
    <footer
        class="fixed bottom-0 left-0 z-40 mx-auto h-20 w-full bg-white shadow-footer transition"
        :class="{ 'translate-y-full opacity-0': !showFooter }"
    >
        <div class="mx-auto flex max-w-7xl items-center justify-between px-6 py-4 font-light">
            <VFooterItem
                :active="isActive('home')"
                :text="$t('Heim')"
                @click="$router.push({ name: 'home' })"
            >
                <VIcon
                    name="home"
                    class="w-5"
                />
            </VFooterItem>

            <VFooterItem
                :active="isActive('profile')"
                :text="$t('Mín brúkari')"
                @click="$router.push({ name: 'profile' })"
            >
                <VIcon
                    name="user"
                    class="w-5"
                />
            </VFooterItem>

            <VFooterItem
                :active="isActive('menu')"
                :text="$t('Menu')"
                @click="$router.push({ name: 'menu' })"
            >
                <VIcon
                    name="menu"
                    class="w-5"
                />
            </VFooterItem>

            <div
                @click.prevent="open2fyri1"
                class="grid h-11 w-11 cursor-pointer place-items-center rounded-full bg-light-blue-500"
            >
                <VIcon
                    name="2fyri1-logo"
                    class="w-9"
                />
            </div>
        </div>
    </footer>
</template>
