import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type { Plan } from '@/models/plan.model';
import type { PlanResponse } from '@/models/plan.model';

export default class PlansService {
    public static url = `${import.meta.env.VITE_API_URL}/plans`;

    public static async get() {
        return createHttpResponse<PlanResponse>(axios.get(`${this.url}`));
    }

    public static async show(planId: string | number) {
        return createHttpResponse<Plan>(axios.get(`${this.url}/${planId}`));
    }
}
