import { computed, ref, type Ref } from 'vue';

import { defineStore } from 'pinia';

import { mapUser, type errorMessage } from '@/helpers';

import type Action from '@/models/action.model';
import type Agreement from '@/models/agreement.model';
import type DataTransfer from '@/models/data-transfer.model';
import type Notification from '@/models/notification.model';
import type Package from '@/models/package.model';
import type { Plan } from '@/models/plan.model';
import type { ReceiptSingle } from '@/models/receipt.model';
import type Status from '@/models/status.model';

import type { PlanResponse } from '@/models/plan.model';

export const useStore = defineStore('general', () => {
    const showFooter = ref(true);

    const agreement = ref<Agreement>();

    const error: Ref<Parameters<typeof errorMessage>[0]> = ref({});

    const isRefreshingTokens = ref(false);

    const dataTransfers: Ref<DataTransfer[]> = ref([]);

    const plan = ref<Plan>();
    const plans: Ref<Plan[]> = ref([]);
    const planResponse = ref<PlanResponse>();
    const planType = ref<PlanResponse[number]>();

    const packages: Ref<Package[]> = ref([]);


    const actions: Ref<Action[]> = ref([]);

    const notifications: Ref<Notification[]> = ref([]);
    const unreadNotifications = ref(0);

    const activeReceipt: Ref<ReceiptSingle | null> = ref(null);

    const statusMessages: Ref<Status['messages']> = ref([]);

    return {
        isRefreshingTokens,
        activeReceipt,
        dataTransfers,
        actions,
        plan,
        plans,
        planResponse,
        planType,
        notifications,
        unreadNotifications,
        statusMessages,
        showFooter,
        error,
        packages,
        agreement,

        // Computed

        people: computed(() => {
            const familyMembers = $subscription.familyMembers || [];
            const individuals = $subscription.individuals.filter((user) => !user?.currentMember?.phoneNumber);

            // Add people from Subscription

            const allPeople = [
                mapUser($subscription.currentAccount?.currentMember),
                ...familyMembers.map((familyMember) => mapUser(familyMember)),
                ...individuals.map((user) =>  mapUser(user?.members?.[0])),
            ];

            return allPeople;
        }),

        activePlan: computed(() => {
            if (!$subscription.currentAccount || !plans.value.length) {
                return null;
            }

            const subscriptionPlanId = $subscription.currentAccount.planId ?? '0';

            return plans.value.find((plan) => plan.planId == subscriptionPlanId);
        }),

        // Functions

        getPlanType(planTypeId: string) {
            return planResponse.value?.find((planType) => planType.id == planTypeId);
        },

        getPlanTypeFromPlan(planId: string) {
            return planResponse.value?.find((planType) => planType.dataPlanResponses.some((plan) => plan.planId == planId));
        },

        async $reset() {
            error.value = {};
            showFooter.value = true;
            plans.value = [];
            planResponse.value = undefined;
            planType.value = undefined;
            packages.value = [];
            actions.value = [];
            notifications.value = [];
            unreadNotifications.value = 0;
            agreement.value = undefined;
        },
    };
});
