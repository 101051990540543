import i18n from '@/i18n';
import dayjs from 'dayjs';

function getDateFromString(string: string) {
    if (/\d{2}.\d{2}.\d{4}/.test(string)) {
        const split = string.split('.');

        return new Date(`${split[2]}-${split[1]}-${split[0]}`);
    }

    return new Date(string);
}

function getHoursMinutes(seconds: number) {
    if (typeof seconds !== 'number') {
        throw new Error('Invalid input. Seconds must be a non-negative number.');
    }

    if (seconds < 0) {
        return { hours: 0, minutes: 0 };
    }

    const hours = Math.floor(seconds / 3600);

    const remainingSeconds = seconds % 3600;

    const minutes = Math.floor(remainingSeconds / 60);

    return { hours, minutes };
}

/**
 * Formatter module for formatting various data types such as numbers, dates, and data sizes.
 * @module Formatter
 */
export const Formatter = {
    getLocale() {
        return i18n.global.locale.value == 'fo' ? 'da' : i18n.global.locale.value; // Google Chrome doesn't recognize 'fo'
    },

    data(data: number | string | undefined | null): string {
        if (!data) {
            return '0';
        }

        if (+data >= 1024000) {
            return '∞';
        }

        if (typeof data == 'string') {
            data = Number(data);
        }

        if (isNaN(data)) {
            throw Error(`Formatter.data(): "${data}" is not a string.`);
        }

        // Divide data by 1024 to convert gigabytes.
        const amount = data / 1024;

        // If the amount is not a whole number, return it with zero decimal places.
        if (amount % 1 != 0 || amount < 0) {
            return amount.toFixed(1);
        }

        return amount.toString();
    },

    number(number: number | string | undefined, options: Intl.NumberFormatOptions = {}): string {
        if (!number) {
            return '0';
        }

        if (typeof number == 'string') {
            number = Number(number);
        }

        return new Intl.NumberFormat(this.getLocale(), {
            ...options,
        }).format(number);
    },

    date(date: Date | string | number = new Date(), format = 'DD.MM.YYYY'): string {
        if (typeof date == 'string') {
            date = getDateFromString(date);
        }

        return dayjs(date).format(format);
    },

    /**
     * Formats a number from seconds to a string.
     *
     * @param {number} number - The number to format.
     * @returns {string} The formatted number string.
     */
    time(seconds: number) {
        const hoursMinutes = getHoursMinutes(seconds);

        if (hoursMinutes.hours === 0) {
            return $t('{minutes} min', { minutes: hoursMinutes.minutes });
        }

        return $t('{hours} t og {minutes} min', hoursMinutes);
    },

    /**
     * Formats a number with the specified options and locale.
     *
     * @param {number | string} number - The number to format.
     * @returns {string} The formatted number string.
     */
    phone(number: number | string) {
        const numberString = number.toString();
        const lastSixCharacters = numberString.slice(-6);

        return parseInt(lastSixCharacters, 10);
    },

    /**
     * Turns every null to empty string
     *
     * @param {Pbject} object - The number to format.
     * @returns {string} The formatted number string.
     */
    nullToString(object: Object) {
        if (typeof object !== 'object' || object === null) {
            throw new Error('Invalid input. Input must be a non-null object.');
        }

        const result = {};

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                result[key] = object[key] === null ? '' : object[key];
            }
        }

        return result;
    },

    cleanPhoneNumber(input: string) {
        const cleaned = input.replace(/^(?:\+?298|00298)/, '');
    
        return cleaned.length === 6 ? cleaned : input;
    }
};
