<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';

import { EventBus } from '@/helpers/event-bus.helper';
import { Modal } from '@/helpers/modal.helper';

import ThePolling from '@/components/layout/ThePolling.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import TheModals from '@/components/layout/TheModals.vue';
import TheToasts from '@/components/layout/TheToasts.vue';

EventBus.init();

// Lifecycle hooks

onMounted(() => {
    window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
            Modal.close();
        }
    });
});
</script>

<template>
    <ThePolling />

    <RouterView />

    <TheFooter />

    <TheModals />

    <TheToasts />
</template>
