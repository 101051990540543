<script setup lang="ts">
import { Capacitor } from '@capacitor/core';

import VButton from '@/components/VButton.vue';
import VModal from '@/components/modals/VModal.vue';
import { ref } from 'vue';

// Props

defineProps({
    active: {
        type: Boolean,
        required: true,
    },
});

// Emit

const emit = defineEmits(['close']);

// Data

const websiteLink = ref(import.meta.env.VITE_2FYRI1_WEBSITE_LINK);
const appStoreLink = ref(import.meta.env.VITE_2FYRI1_APP_STORE_LINK);
const playStoreLink = ref(import.meta.env.VITE_2FYRI1_PLAY_STORE_LINK);

// Functions

function closeModal() {
    emit('close');
}
</script>

<template>
    <VModal
        :active="active"
        @close="emit('close')"
    >
        <div class="flex flex-col gap-8">
            <div>
                <div class="relative mb-2 flex items-center">
                    <div
                        @click.prevent="closeModal"
                        class="relative z-10 -ml-3 mr-3 grid h-11 w-11 cursor-pointer place-items-center rounded transition hover:bg-gray-100"
                    >
                        <VIcon
                            name="arrow"
                            class="w-4"
                        />
                    </div>

                    <h4 class="pointer-events-none">{{ $t('2 fyri 1 appin') }}</h4>
                </div>

                <p>
                    {{
                        $t(
                            'Nema vil saman við sínum samstarvsfeløgum bjóða tykkum hesi frálíku tilboð, sum vit kalla 2 fyri 1. So kunnu tit steðga á eina løtu, leggja telefonina frá tykkum, sløkkja telduna og bjóða onkrum, sum tit eru góð við, við til ein súkklutúr, biograf-vitjan, kaffimunn ella ein bita, har tit bara kunnu njóta samveruna og løða upp aftur.',
                        )
                    }}
                </p>
            </div>

            <img
                alt=""
                src="@/assets/images/2fyri1.png"
                class="w-full object-contain"
            />

            <div>
                <h4 class="text-center">{{ $t('Heinta appina') }}</h4>
                <p class="text-center">{{ $t('og fá atgongd til allar virðisseðlarnar') }}</p>
            </div>

            <div class="flex justify-center gap-7">
                <a
                    v-if="Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'android'"
                    :href="playStoreLink"
                    target="_blank"
                >
                    <img
                        alt=""
                        src="../../assets/images/play-store.jpg"
                        class="h-12 object-contain"
                    />
                </a>

                <a
                    v-if="Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'ios'"
                    :href="appStoreLink"
                    target="_blank"
                >
                    <img
                        alt=""
                        src="../../assets/images/app-store.jpg"
                        class="h-12 object-contain"
                    />
                </a>
            </div>

            <div>
                <a
                    :href="websiteLink"
                    target="_blank"
                    class="btn outlined mb-2"
                    >{{ $t('Sí tilboð á nema.fo') }}</a
                >

                <VButton
                    :label="$t('Lat aftur')"
                    @click="closeModal"
                />
            </div>
        </div>
    </VModal>
</template>
