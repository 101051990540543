import { computed } from 'vue';
import menuRoutes from './menu.routes';
import profileRoutes from './profile.routes';

export default [
    {
        path: '/',
        component: () => import('@/views/MainView.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/home/HomeView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: false,
                        title: computed(() => $t('Mítt Nema')),
                    },
                },
            },

            {
                path: '/valkomin',
                name: 'home-welcome',
                component: () => import('@/views/WelcomeView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hideNotification: true,
                        title: $t('Vælkomin til Nema'),
                    },
                },
            },

            {
                path: 'frabodanir',
                name: 'notifications',
                component: () => import('@/views/home/NotificationsView.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Fráboðanir')),
                    },
                },
            },

            {
                path: 'familju-limur/:id',
                name: 'home-familymember',
                component: () => import('@/views/home/FamilyMemberView.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Um familjulim')),
                    },
                },
            },

            {
                path: 'familju-limur/vidgerd/:phoneNumber',
                name: 'home-familymember-phone',
                component: () => import('@/views/home/FamilyMemberView.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Um familjulim')),
                    },
                },
            },

            {
                path: 'umsit-hald/:phoneNumber',
                name: 'home-manage',
                component: () => import('@/views/home/ManageSubscriptionView.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Umsit hald')),
                    },
                },
            },

            {
                path: 'umsit-familju-hald',
                name: 'home-manage-family',
                component: () => import('@/views/home/ManageFamilySubscriptionView.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Umsit hald')),
                    },
                },
            },

            {
                path: '/:pathMatch(.*)*',
                redirect: () => {
                    return { name: 'home' };
                },
            },

            ...profileRoutes,
            ...menuRoutes,
        ],
    },
];
