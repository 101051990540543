<script setup lang="ts">
import i18n from '@/i18n';
import { nextTick, onMounted, ref, watch, type Ref } from 'vue';

// Props

defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
});

// Emits

defineEmits(['click']);

// Data

const tab: Ref<null | HTMLElement> = ref(null);

// Lifecycle Hooks

onMounted(() => {
    setItemWidth();

    let debounce = 0;

    window.addEventListener('resize', () => {
        clearTimeout(debounce);

        debounce = setTimeout(() => {
            setItemWidth();
        }, 250);
    });
});

// Watchers

watch([i18n.global.locale], () => {
    setItemWidth();
});

// Functions

async function setItemWidth() {
    if (!tab.value) {
        return;
    }

    tab.value.style.width = '';

    await nextTick();

    if (!tab.value) {
        return;
    }

    tab.value.style.width = `${tab.value.scrollWidth}px`;
}
</script>

<template>
    <div
        ref="tab"
        @click.prevent="$emit('click')"
        class="flex flex-shrink-0 flex-grow-0 cursor-pointer items-center overflow-hidden rounded-full transition-all duration-300 ease-out hover:bg-purple-50"
        :class="{
            'bg-purple-50': active,
            '!w-11': !active,
        }"
    >
        <div class="grid h-11 w-11 flex-shrink-0 place-items-center">
            <slot />
        </div>

        <p class="whitespace-nowrap pr-3">{{ text }}</p>
    </div>
</template>
