export default [
    {
        path: '/rita-inn',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            guest: true,
            index: 400,
            auth: false,
            footer: false,
            header: {
                transparent: true,
            },
        },
        children: [
            {
                path: '',
                name: 'auth.login',
                component: () => import('@/views/auth/LoginView.vue'),
                meta: {
                    index: 401,
                    footer: false,
                    header: {
                        hasBackButton: false,
                        transparent: true,
                    },
                },
            },
            {
                path: 'vid-telefonnummari',
                name: 'login.number',
                component: () => import('@/views/auth/NumberView.vue'),
                meta: {
                    index: 401,
                    footer: false,
                    header: {
                        hasBackButton: true,
                        transparent: true,
                    },
                },
            },
            {
                path: 'pin',
                name: 'login.pin',
                component: () => import('@/views/auth/PinView.vue'),
                meta: {
                    index: 402,
                    footer: false,
                    header: {
                        hasBackButton: true,
                        transparent: true,
                    },
                },
            },
            {
                path: 'vid-telduposti',
                name: 'login.email',
                component: () => import('@/views/auth/EmailView.vue'),
                meta: {
                    index: 401,
                    footer: false,
                    header: {
                        hasBackButton: true,
                        transparent: true,
                    },
                },
            },
        ],
    },
    {
        path: '/gloymt-loyniord',
        name: 'forgot-password',
        component: () => import('@/views/auth/ForgotPasswordView.vue'),
        meta: {
            index: 400,
            auth: false,
            footer: false,
            header: {
                hasBackButton: true,
                transparent: true,
            },
        },
    },
    {
        path: '/nytt-loyniord',
        name: 'reset-password',
        component: () => import('@/views/auth/ResetPasswordView.vue'),
        meta: {
            index: 400,
            auth: false,
            footer: false,
            header: {
                hasBackButton: true,
                transparent: true,
            },
        },
    },
    {
        path: '/stovna-brukara',
        name: 'register',
        component: () => import('@/views/auth/RegisterView.vue'),
        meta: {
            guest: true,
            index: 400,
            auth: false,
            footer: false,
            header: {
                hasBackButton: true,
                transparent: true,
            },
        },
    },
];
