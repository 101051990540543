import { computed } from 'vue';

export default [
    {
        path: '/min-brukari',
        name: 'profile',
        component: () => import('@/views/profile/ProfileView.vue'),
        meta: {
            auth: true,
            footer: true,
            header: {
                hasBackButton: false,
                title: computed(() => $t('Mín brúkari')),
            },
        },
    },
    {
        path: '/min-brukari/gjaldsavtala',
        name: 'profile.agreement',
        component: () => import('@/views/profile/ProfilePaymentView.vue'),
        meta: {
            auth: true,
            footer: false,
            header: {
                hasBackButton: false,
                hideNotification: true,
            },
        },
    },
];
