import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type User from '@/models/user.model';

export default class AuthService {
    public static url = `${import.meta.env.VITE_API_URL}/auth`;

    public static async me() {
        return createHttpResponse<User>(axios.get(`${this.url}/me`));
    }

    public static async requestPin(phoneNumber: string) {
        return createHttpResponse<void>(
            axios.post(`${this.url}/request-pin`, {
                phoneNumber: `298${phoneNumber}`,
            }),
        );
    }

    public static async enterPin(phoneNumber: string, password: string) {
        return createHttpResponse<{ accessToken: string; refreshToken: string }>(
            axios.post(`${this.url}/enter-pin`, {
                phoneNumber: `298${phoneNumber}`,
                password,
            }),
        );
    }

    public static async loginWithEmail(email: string, password: string) {
        return createHttpResponse<{ accessToken: string; refreshToken: string }>(
            axios.post(`${this.url}/email-login`, {
                email,
                password,
            }),
        );
    }

    public static async forgotPassword(email: string) {
        return createHttpResponse(
            axios.post(`${this.url}/forgot-password`, {
                email,
            }),
        );
    }

    public static async resetPassword(data: { email: string; password: string; passwordResetToken: string }) {
        return createHttpResponse(axios.post(`${this.url}/reset-password`, data));
    }

    public static async reserveNumber(phoneNumber: string) {
        return createHttpResponse<{ phoneNumber: string; reservationId: string }>(
            axios.post(`${this.url}/reserved-numbers`, {
                phoneNumber,
            }),
        );
    }

    public static async refreshToken(refreshToken: string) {
        return createHttpResponse<{ accessToken: string; refreshToken: string }>(
            axios.post(`${this.url}/refresh-token`, {
                refreshToken,
            }),
        );
    }

    public static async logout() {
        $auth.$reset();
        $store.$reset();
        $subscription.resetSubscriptionData();
    }
}
